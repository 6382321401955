<template>
   <div class="obi-app">
      <slot />
   </div>
</template>

<script>
export default {
   name: 'ObiApp',
   props: {},
};
</script>
<style lang="scss" scoped>
.obi-app {
   height: 100vh;
}
</style>
